<template>
  <ContentWrapper
    v-bind:classNames="classNames"
    v-bind:idName="idName"
    v-bind:headerTitle="headerTitle"
    v-bind:cardNoBody="cardNoBody"
    v-bind:apiUrl="apiUrl"
    v-bind:isRendering="isRendering"
    v-on:renderContent="renderContent"
  >
    <template v-slot:content>
      <b-row no-gutters>
        <b-col sm="12" md="6" lg="6" xl="6">
          <a v-bind:href="'https://www.youtube.com/watch?v=' + $route.params.videoId" target=_blank>
            <!--<b-card-img v-bind:src="video_detail.thumbnails.high.url"></b-card-img>-->
            <b-card-img v-bind:src="'https://i.ytimg.com/vi/' + $route.params.videoId + '/hqdefault.jpg'"></b-card-img>
          </a>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-card-body>
            <a v-bind:href="'https://www.youtube.com/watch?v=' + $route.params.videoId" target=_blank>
              <!--<b-card-title>{{video_detail.title}}</b-card-title>-->
              <BCardTitle>{{video_detail.title}}</BCardTitle>
            </a>
            <a v-bind:href="'https://www.youtube.com/channel/' + $route.params.channelId" target=_blank>
              <!--<b-card-sub-title class="text-muted">{{video_detail.channelTitle}}</b-card-sub-title>-->
              <BCardSubtitle class="text-muted">{{video_detail.channelTitle}}</BCardSubtitle>
            </a>
            
            <!--
            <b-card-sub-title class="text-muted">
              <a v-bind:href="'https://www.youtube.com/channel/' + $route.params.channelId" target=_blank>
                {{video_detail.channelTitle}}
              </a>
            </b-card-sub-title>
            -->
            
            <!--<b-card-text>{{video_detail.publishedAt | date_format}}</b-card-text>-->
            <b-card-text>{{$filters.date_format(video_detail.publishedAt)}}</b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </template>
  </ContentWrapper>
</template>


<script>
//import './filter.js';
import ContentWrapper from './ContentWrapper.vue';

export default {
  name: 'VideoDetail',
  //name: 'content-video-detail',
  components: {
    ContentWrapper,
  },
  data: function() {
    return {
      //classNames: 'col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3',
      classNames: 'mb-3',
      idName: 'video-detail',
      headerTitle: '動画情報',
      cardNoBody: true, // bodyが自動生成されると画像に余白ができるため。

      // APIのエンドポイント
      //apiUrl: 'https://jsonplaceholder.typicode.com/users?ID=12345',
      isRendering: true, // 描画中かどうかのフラグ。

      // Ajaxで受け取る動画データ。デフォルトは空っぽ。
      video_detail: {
        title: '',
        publishedAt: '',
        channelTitle: '',
        thumbnails: {
          high: {
            url: ''
          }
        }
      }
    }
  },
  computed: {
    apiUrl: function() {
      // 動画の基本情報を取得するAPIのエンドポイントを作成する。
      return [
        //'https://toretora.net/api/video',
        //'https://8yn3yliewk.execute-api.ap-northeast-1.amazonaws.com/prod/api/video',
        //'https://toretora.net/api/video',
        //this.$apiEndpoint + 'video',
        this.$apiDomain + 'api/video',
        this.$route.params.channelId,
        this.$route.params.videoId,
        //'snippet'
        'detail'
        ].join('/');
    },
  },
  methods: {
    renderContent: function(jsonData) {
      // 子コンポーネントでAJAX通信でデータを取得した際に呼び出されるメソッド。
      // コンテンツの描画の処理を行った後、
      // 描画完了のフラグを立て、子コンポーネントに渡す。

      //console.log(this.$route.params['id']);
      //console.log(JSON.stringify(jsonData, null, 2));
      this.video_detail = jsonData;
      //console.log(this.video_detail)

      // 以下テストコード。
      //this.video_detail = {
      //title: 'あああああ',
      //publishedAt: '2020-07-02T11:45:01Z',
      //channelTitle: '有吉の壁【公式】壁チャンネル2',
      //thumbnails: {
      //  high: {
      //    url: 'https://i.ytimg.com/vi/ZtpL14W5Of8/hqdefault.jpg'
      //  }
      //};

      this.isRendering = false;
    }
  },
}
</script>


<style scoped>
</style>
