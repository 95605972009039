<template>
  <div class="container text-muted mt-2 mx-auto">
    <VideoDetail/>

    <div class="row card-columns">
      <WordCloud2/>
      <Omusubi2/>
      <TranscribeResult/>
    </div>
  </div>
</template>


<script>
import VideoDetail from './VideoDetail.vue'
import WordCloud2 from './WordCloud2.vue'
import Omusubi2 from './Omusubi2.vue'
import TranscribeResult from './TranscribeResult.vue'

export default {
  name: 'VideoPage',
  components: {
    VideoDetail,
    WordCloud2,
    Omusubi2,
    TranscribeResult,
  }
}
</script>


<style scoped>
</style>
