//import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import IndexPage from './components/IndexPage.vue';
import VideoPage from './components/VideoPage.vue';
import ContactPage from './components/ContactPage.vue';
import PolicyPage from './components/PolicyPage.vue';

const routes = [
  {
    path: '/',
    component: IndexPage
  },
  {
    //path: '/video',
    //path: '/video/:id',
    path: '/video/:channelId/:videoId',
    component: VideoPage
  },
  {
    path: '/contact',
    component: ContactPage,
  },
  {
    path: '/policy',
    component: PolicyPage
  }
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
})

export default router;