import { createApp } from 'vue'
import App from './App.vue'
//import BootstrapVue3 from 'bootstrap-vue-3'
//import BootstrapVue from 'bootstrap-vue';
import BootstrapVueNext from 'bootstrap-vue-next';
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css';
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import 'nouislider/distribute/nouislider.min.css';

//createApp(App).mount('#app')
const app = createApp(App)

//app.use(BootstrapVue3)
//app.use(BootstrapVue)
app.use(BootstrapVueNext)
app.use(router)

app.mount('#app')

// prod(version 1)
//app.config.globalProperties.$apiDomain = 'https://toretora.net/api/'

// TestHosting
//app.config.globalProperties.$apiDomain = 'https://d195tr34pol2kp.cloudfront.net/'

// ProdHosting(CloudFront Domain)
//app.config.globalProperties.$apiDomain = 'https://d2s0pepg2vqn96.cloudfront.net/'

// ProdHosting(version 2)
app.config.globalProperties.$apiDomain = 'https://toretora.net/'

app.config.globalProperties.$filters = {
  date_format(publishedAt) {
    //return publishedAt.split('T')[0]
    return publishedAt
  }
}