<template>
  <div class="card my-2">
    <!--<router-link v-bind:to="video.id + '.html'">-->
    <!--<router-link v-bind:to="'video/' + video.id">-->
    <router-link v-bind:to="this.videoPageUrl">
      <div class="row">
        <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!--<img v-bind:src="'img/' + video.id + '.png'" class="img-fluid" alt="Responsive image" style="width: 100%;" onerror="this.src='img/nowprinting.png';">-->
          <!--<img v-bind:src="'https://toretora.net/img/' + video.id + '.png'" class="img-fluid" alt="Responsive image" style="width: 100%;" onerror="this.src='img/nowprinting.png';">-->
          <!--<img v-bind:src="'img/' + video.id + '.png'" class="img-fluid" alt="Responsive image" style="width: 100%;" onerror="this.src='img/nowprinting.png';">-->
          <img v-bind:src="'https://img.youtube.com/vi/' + video.id + '/hqdefault.jpg'" class="img-fluid" alt="Responsive image" style="width: 100%;" onerror="this.src='img/nowprinting.png';">
        </div>
        <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <div class="card-body">
            <h5>{{$filters.date_format(video.publishedAt)}}</h5>
            <h5>{{video.channelTitle}}の動画チャート</h5>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>


<script>
export default {
  name: 'VideoOutline',
  props: [
    'video',
  ],
  computed: {
    videoPageUrl: function() {
      return ['video', this.video.channelId, this.video.id].join('/');
    }
  },
}
</script>


<style scoped>
</style>