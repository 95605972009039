<template>
  <div v-bind:id="idName" v-bind:class="classNames">
    <b-card
      v-bind:header="headerTitle"
      v-bind:no-body="cardNoBody"
      header-tag="header"
      header-bg-variant="white"
    >
      <LoaderSpinner v-show="isRendering"></LoaderSpinner>

      <transition name="fade">
        <div v-show="!isRendering">
          <slot name="content"></slot>
        </div>
      </transition>
    </b-card>
  </div>
</template>


<script>
import axios from 'axios';
import LoaderSpinner from './LoaderSpinner.vue';

export default {
  name: 'ContentWrapper',
  components: {
    LoaderSpinner,
  },
  props: [
    'classNames', // 親コンポーネントから渡されるclass情報
    'idName', // 親コンポーネントから渡されるid情報
    'headerTitle', // ヘッダーのタイトル名
    'cardNoBody', // cardタグにbodyが不要かどうか
    'apiUrl', // 読み込むAPIのURL。

    // コンテンツを描画している最中かかどうか。
    // 最初はtrue、コンテンツの描画が完了するとfalseが渡される。
    'isRendering',
  ],
  data: function() {
    return {
      isVisible: false, // コンテンツがブラウザの可視領域に入ったかどうか。
      margin: 100, // コンテンツが可視領域に至るまでのマージン。
      //margin: -200, // コンテンツが可視領域に至るまでのマージン。
    }
  },
  computed: {
  },
  created: function() {
    window.addEventListener('scroll', this.handleScrollEvent);
  },
  mounted: function() {
    // 既にブラウザの可視領域に入っている場合に対応するための記述。
    this.handleScrollEvent();
    //this.$nextTick(function() {
    //  this.handleScrollEvent();
    //});
  },
  destoryed: function() {
    window.removeEventListener('scroll', this.handleScrollEvent);
  },
  methods: {
    handleScrollEvent: function() {
      // スクロールし、コンテンツがブラウザで見える位置までスクロールされたら
      // 表示されるように設定する。
      if (!this.isVisible) {
        //  ブラウザの可視領域に近づいた時の処理。
        var top = this.$el.getBoundingClientRect().top;
        if (top < window.innerHeight + this.margin) {
          // 可視領域に来たため、次回以降は本処理を止める。
          this.isVisible = true;
          var me = this;

          // 親コンポーネントから渡れたAPIのURLにAJAXでアクセスする。
          axios.get(this.apiUrl)
            .then(function (response) {
              // 取得したデータを親コンポーネントに渡して、処理を引き継ぐ。
              me.$emit('renderContent', response.data);
              
              //me.$nextTick(function() {
              //  me.$emit('renderContent', response.data);
              //});
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              // always executed(axiosの処理結果によらずいつも実行させたい処理を記述)
            });
        }
      }
    }
  }
}
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
