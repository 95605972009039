<template>
  <ContentWrapper
    v-bind:classNames="classNames"
    v-bind:idName="idName"
    v-bind:headerTitle="headerTitle"
    v-bind:apiUrl="apiUrl"
    v-bind:isRendering="isRendering"
    v-on:renderContent="renderContent"
  >
    <template v-slot:content>
      <div id="transcribe-result-wrapper">
        <textarea v-model="transcribeResultJson" id="transcribe-textarea" class="form-control" size="12" rows="10" readonly></textarea>
      </div>
    </template>
  </ContentWrapper>
</template>


<script>
import ContentWrapper from './ContentWrapper.vue';

export default {
  //name: 'TranscribeResult',
  name: 'content-transcribe-result',
  components: {
    ContentWrapper,
  },
  data: function() {
    return {
      //transcribeResult: {'status': 'COMPLETED'},
      classNames: 'col-sm-12 col-md-12 col-lg-12 col-xl-12',
      idName: 'transcribe-result',
      headerTitle: 'Amazon Transcribe 結果',
      // APIのエンドポイント
      // apiUrl: 'https://jsonplaceholder.typicode.com/users?ID=12345',
      isRendering: true, // 描画中かどうかのフラグ。
      
      transcribeResult: {},
    };
  },
  computed: {
    apiUrl: function() {
      // 動画の基本情報を取得するAPIのエンドポイントを作成する。
      return [
        //'https://toretora.net/api/video',
        //'https://8yn3yliewk.execute-api.ap-northeast-1.amazonaws.com/prod/api/video',
        //'https://toretora.net/api/video',
        //this.$apiEndpoint + 'video',
        this.$apiDomain + 'api/video',
        this.$route.params.channelId,
        this.$route.params.videoId,
        'transcribe'
        ].join('/');
    },
    transcribeResultJson: function() {
      return JSON.stringify(this.transcribeResult, null, 2);
    }
  },
  methods: {
    renderContent: function(jsonData) {
      // 子コンポーネントでAJAX通信でデータを取得した際に呼び出されるメソッド。
      // コンテンツの描画の処理を行った後、
      // 描画完了のフラグを立て、子コンポーネントに渡す。

      //console.log(JSON.stringify(jsonData, null, 2));
      this.transcribeResult = jsonData;
      this.isRendering = false;
    }
  },
}
</script>


<style scoped>
#transcribe-result {
  width: 100%;
}

#transcribe-result-wrapper {
  width: 100%;
  //height: 200px;
}
</style>
