<template>
  <div>
    <b-button v-b-toggle="'toggle'+sliderId" variant="primary">Range</b-button>
    <b-collapse v-bind:id="'toggle'+sliderId">
      <b-card-footer footer-bg-variant="white" class="border-0 pt-4 pb-5">
        <div v-bind:id="'slider'+sliderId" class="slider"></div>
      </b-card-footer>
    </b-collapse>
  </div>
</template>


<script>
import noUiSlider from 'nouislider'

export default {
  name: 'ToggleSlider',
  props: [
    'sliderId',
  ],
  data: function() {
    return {
      sliderIdSuffix: 'SliderParam',
    };
  },
  mounted: function() {
    var me = this;

    //var sliders = document.getElementsByClassName("slider");
    var slider = document.getElementById('slider' + this.sliderId);
    noUiSlider.create(slider, {
      start: [0, 100], // ハンドルの初期位置を指定。数を増やせばハンドルの数も増える。
      step: 5, // スライダを動かす最小範囲を指定。
      margin: 5, // ハンドル間の最低距離を指定。
      connect: true, // ハンドル間を色塗りするかどうか
      direction: 'ltr', // どちらを始点にするか。ltr(Left To Right) or rtl(Right To Left)。
      orientation: 'horizontal', // スライダーの方向。横向きか縦か。縦の場合は、cssでrangeのheightを適当に設定しないとつぶれてしまう。
      behaviour: 'tap-drag', // ハンドルの動かし方。
      range: {
          'min': 0,
          'max': 100
      }, // スライダーの始点と終点
      pips: { //pipを使うと、スライダーに目盛りがつく。
          mode: 'steps', // stepごとに大きな目盛りがつく。
          density: 5 // densityはrangeで指定した範囲の何%ごとに小さな目盛りを振るか(デフォルト1)
      }
    });

    slider.noUiSlider.on("change", function(values) {
      var lower = parseFloat(values[0] / 100);
      var upper = parseFloat(values[1] / 100);
      //console.log(lower);
      //console.log(upper);

      me.$emit(
        //'wordCloudSliderParam', 
        me.sliderId + me.sliderIdSuffix,
        {
          'lower': lower,
          'upper': upper
        }
      );
    });
  },
}
</script>


<style scoped>
</style>
