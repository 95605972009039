<template>
  <div class="container text-muted mt-2 mx-auto">
    <SearchLatest/>
  </div>
</template>


<script>
//import VideoList from './VideoList.vue';
import SearchLatest from './SearchLatest.vue';

export default {
  name: 'IndexPage',
  components: {
    //VideoList,
    SearchLatest,
  }
}
</script>


<style scoped>
</style>