<template>
  <div v-bind:id="idName" v-bind:class="classNames">
      <LoaderSpinner v-show="isRendering"></LoaderSpinner>
      <div v-show="!isRendering">
        <h2>{{headerTitle}}</h2>
        <VideoOutline v-for="video in videos" v-bind:video="video" :key="video.id"/>
      </div>
  </div>
</template>


<script>
import axios from 'axios';
import LoaderSpinner from './LoaderSpinner.vue';
import VideoOutline from './VideoOutline.vue'

export default {
  name: 'SearchWrapper',
  components: {
    LoaderSpinner,
    VideoOutline,
  },
  props: [
    'classNames', // 親コンポーネントから渡されるclass情報
    'idName', // 親コンポーネントから渡されるid情報
    'headerTitle', // hタグの中身
    'videos', // 検索結果の動画オブジェクト
    'apiUrl', // 読み込むAPIのURL。
    // コンテンツを描画している最中かかどうか。
    // 最初はtrue、コンテンツの描画が完了するとfalseが渡される。
    'isRendering',
  ],
  data: function() {
    return {
      isVisible: false, // コンテンツがブラウザの可視領域に入ったかどうか。
      margin: 100, // コンテンツが可視領域に至るまでのマージン。

      //videos: [
      //  {
      //    id: 'aaaaa',
      //    publishedAt: '2020-07-01T11:45:01Z',
      //    channelTitle: '有吉の壁【公式】壁チャンネル'
      //  },
      //  {
      //    id: 'bbbbb',
      //    publishedAt: '2020-07-02T11:45:01Z',
      //    channelTitle: '有吉の壁【公式】壁チャンネル2'
      //  },
      //]
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScrollEvent);
  },
  mounted: function() {
    // 既にブラウザの可視領域に入っている場合に対応するための記述。
    this.handleScrollEvent();
  },
  destoryed: function() {
    window.removeEventListener('scroll', this.handleScrollEvent);
  },
  methods: {
    handleScrollEvent: function() {
      // スクロールし、コンテンツがブラウザで見える位置までスクロールされたら
      // 表示されるように設定する。
      if (!this.isVisible) {
        //  ブラウザの可視領域に近づいた時の処理。
        var top = this.$el.getBoundingClientRect().top;
        if (top < window.innerHeight + this.margin) {
          // 可視領域に来たため、次回以降は本処理を止める。
          this.isVisible = true;
          var me = this;

          // 親コンポーネントから渡れたAPIのURLにAJAXでアクセスする。
          axios.get(this.apiUrl)
            .then(function (response) {
              // 取得したデータを親コンポーネントに渡して、処理を引き継ぐ。
              me.$emit('renderContent', response.data);
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              // always executed(axiosの処理結果によらずいつも実行させたい処理を記述)
            });
        }
      }
    }
  }
}
</script>


<style scoped>
</style>
