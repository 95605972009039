<template>
  <div class="container text-muted mt-2 mx-auto">
    <h2>お問い合わせ</h2>
    <p>ご意見・ご質問は以下のメールアドレスへご連絡ください。</p>
    <p>syou.tyokunobo@gmail.com</p>
  </div>
</template>


<script>

export default {
  name: 'ContactPage',
  //components: {
  //  //VideoList,
  //  SearchLatest,
  //}
}
</script>


<style scoped>
</style>