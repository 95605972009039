<template>
  <SearchWrapper
    v-bind:idName="idName"
    v-bind:classNames="classNames"
    v-bind:headerTitle="headerTitle"
    v-bind:videos="videos"
    v-bind:apiUrl="apiUrl"
    v-bind:isRendering="isRendering"
    v-on:renderContent="renderContent"
  >
  </SearchWrapper>
</template>


<script>
import SearchWrapper from './SearchWrapper.vue';

export default {
  name: 'SearchLatest',
  components: {
    SearchWrapper,
  },
  data: function() {
    return {
      idName: 'search-latest',
      classNames: 'video-list',
      headerTitle: '最新動画',
      videos: null,
      //apiUrl: 'https://toretora.net/api/search/latest',
      //apiUrl: 'https://8yn3yliewk.execute-api.ap-northeast-1.amazonaws.com/prod/api/search/latest',
      //apiUrl: 'https://toretora.net/api/search/latest',
      //apiUrl: this.$apiEndpoint + 'search/latest',
      apiUrl: this.$apiDomain + 'api/search/latest',
      isRendering: true, // 描画中かどうかのフラグ。
    };
  },
  methods: {
    renderContent: function(jsonData) {
      // 子コンポーネントでAJAX通信でデータを取得した際に呼び出されるメソッド。
      // コンテンツの描画の処理を行った後、
      // 描画完了のフラグを立て、子コンポーネントに渡す。
      //console.log(JSON.stringify(jsonData, null, 2));
      this.videos = jsonData;
      this.isRendering = false;
    },
  },
}
</script>


<style scoped>
</style>