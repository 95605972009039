<template>
  <ContentWrapper
    v-bind:classNames="classNames"
    v-bind:idName="idName"
    v-bind:headerTitle="headerTitle"
    v-bind:apiUrl="apiUrl"
    v-bind:isRendering="isRendering"
    v-on:renderContent="renderContent"
  >
    <template v-slot:content>
      <div id="wordcloud-wrapper" v-bind:style="{height: canvasWrapperHeightWithPx}">
        <canvas id="my_canvas" v-bind:width="canvasWidth" v-bind:height="canvasHeight" v-bind:style="{width: canvasStyleWidth, height: canvasStyleHeight}"></canvas>
      </div>
      <ToggleSlider v-bind:sliderId="sliderId" v-on:WordCloud2SliderParam="setSliderParam"/>
    </template>
  </ContentWrapper>
</template>


<script>
import $ from 'jquery';
import WordCloud from 'wordcloud';

import ContentWrapper from './ContentWrapper.vue';
import ToggleSlider from './ToggleSlider.vue';

export default {
  name: 'WordCloud2',
  //name: 'content-wordcloud2',
  components: {
    ContentWrapper,
    ToggleSlider,
  },
  data: function() {
    return {
      classNames: 'col-sm-12 col-md-12 col-lg-12 col-xl-12',
      idName: 'WordCloud2',
      headerTitle: 'ワードクラウド',
      // APIのエンドポイント
      //apiUrl: 'https://jsonplaceholder.typicode.com/users?ID=12345',
      isRendering: true, // 描画中かどうかのフラグ。

      sliderId: 'WordCloud2',
      canvasId: 'my_canvas', // canvasのID。
      canvasWrapperId: 'wordcloud-wrapper', // canvasの親divのID。
      canvas: null, // canvasそのもの
      canvasWrapper: null, //canvasのwapperオブジェクト
      canvasWrapperWidth: null, // wapperの幅。

      lower: 0.0, // スライダーで指定した範囲の下限。
      upper: 1.0, // スライダーで指定した範囲の上限。
      minFrequency: null, // 範囲内で抽出された語の頻度の最小値。
      maxFrequency: null, // 範囲内で抽出された語の頻度の最大値。
      data: null, // Ajaxなどで受け取った生データ。
      //data: {
      //  "あい": [0.65, 0.66],
      //  "子音": [0.38],
      //  "一言": [0.0, 0.44, 0.55],
      //  "考え": [0.12, 0.13, 0.13, 0.15]
      //  },
      dataFiltered: [],
    };
  },
  mounted: function() {
    // canvasとそのwrapperのdivのelementを取得する。
    this.canvas = $(this.canvasIdForjQuery)[0];
    //this.canvas = this.$refs.canvas;
    this.canvasWrapper = $(this.canvasWrapperIdForjQuery);
    //console.log(this.canvas);
    //console.log(this.canvasWrapper);
    
    //this.runWordCloud2();
    
    //this.canvasWrapperWidth = this.canvasWrapper.width();
    //this.canvasWrapperWidth = 1000;
    //this.canvasWrapperWidth = this.canvasWrapper.outerWidth();
    //console.log(this.canvasWrapperWidth);
    
    //this.$nextTick(function() {
    //  // canvasとそのwrapperのdivのelementを取得する。
    //  this.canvasWrapperWidth = this.canvasWrapper.width();
    //  
    //  //this.runWordCloud2();
    //});
  },
  updated: function() {
    //console.log('updated');
    // wapperの幅を取得する。
    //this.canvasWrapperWidth = this.canvasWrapper.width();
    //console.log(this.canvasWrapperWidth);
    //console.log(this.canvasWrapper.width());

    //this.runWordCloud2();
    
    // 初回の描画。全範囲で描画する。
    // $nextTickでDOMのレンダリングが完了するまで待つ。
    var me = this;
    this.$nextTick(function() {
      // canvasとそのwrapperのdivのelementを取得する。
      this.canvasWrapperWidth = this.canvasWrapper.width();
      
      //this.runWordCloud2();
      
      // Vue3に以降してから、初回の処理が正常に動作しない。
      // おそらくcanvas側の初期化処理よりも早くWorcCloudが動作しているものと考えられる。
      // ワークアラウンドとして、setTimeoutで1秒のディレイを入れることで対応する。
      var testFunc = function() {
        me.runWordCloud2();
      }
      setTimeout(testFunc, 1000);
    });
  },
  //beforeUnmount: function() {
  //  this.runWordCloud2();
  //},
  computed: {
    apiUrl: function() {
      // 動画の基本情報を取得するAPIのエンドポイントを作成する。
      return [
        //'https://toretora.net/api/video',
        //'https://8yn3yliewk.execute-api.ap-northeast-1.amazonaws.com/prod/api/video',
        //'https://toretora.net/api/video',
        //this.$apiEndpoint + 'video',
        this.$apiDomain + 'api/video',
        this.$route.params.channelId,
        this.$route.params.videoId,
        'wordcloud2'
        ].join('/');
    },
    canvasIdForjQuery: function() {
      return '#' + this.canvasId;
    },
    canvasWrapperIdForjQuery: function() {
      return '#' + this.canvasWrapperId;
    },
    canvasWrapperHeight: function() {
      // wapperの高さを返す。
      //console.log(Math.floor(this.canvasWrapperWidth * 0.7));
      return Math.floor(this.canvasWrapperWidth * 0.7);
    },
    canvasWrapperWidthWithPx: function() {
      // wapperの幅をpx付きで文字列として返す。
      return String(this.canvasWrapperWidth) + 'px';
    },
    canvasWrapperHeightWithPx: function() {
      // wapperの高さをpx付きで文字列として返す。
      return String(this.canvasWrapperHeight) + 'px';
    },
    canvasWidth: function() {
      // canvasの幅を返す。※ 高解像度に対応するため2倍としている。
      return this.canvasWrapperWidth * 2;
    },
    canvasHeight: function() {
      // canvasの高さを返す。※ 高解像度に対応するため2倍としている。
      return this.canvasWrapperHeight * 2;
    },
    canvasStyleWidth: function() {
      // canvasのstyle上の幅をpx付きで文字列として返す。
      //console.log(this.canvasWrapperWidthWithPx);
      return this.canvasWrapperWidthWithPx;
    },
    canvasStyleHeight: function() {
      // canvasのstyle上の高さをpx付きで文字列として返す。
      //console.log(this.canvasWrapperHeightWithPx);
      return this.canvasWrapperHeightWithPx;
    },
    dataSorted: function(){
      // スライダーの範囲内のデータを出現頻度が多い順にソートする。
      return this.dataFiltered.slice().sort(function(a, b) {
        if (a[1].length > b[1].length) return -1;
        if (a[1].length < b[1].length) return 1;
      });
    }
  },
  methods: {
    renderContent: function(jsonData) {
      //console.log('renderContent');
      //this.canvasWrapperWidth = this.canvasWrapper.width();
      //console.log(this.canvasWrapper.width());
      
      // 子コンポーネントでAJAX通信でデータを取得した際に呼び出されるメソッド。
      // コンテンツの描画の処理を行った後、
      // 描画完了のフラグを立て、子コンポーネントに渡す。
      this.data = JSON.parse(jsonData.WordCloud2);
      //console.log(this.data)

      // 以下テストコード。
      //this.data = {
      //  "あい": [0.65, 0.66],
      //  "子音": [0.38],
      //  "一言": [0.0, 0.44, 0.55],
      //  "考え": [0.12, 0.13, 0.13, 0.15]
      //};

      this.isRendering = false;
      
      this.runWordCloud2();
      //this.$nextTick(function() {
      //  //console.log('renderContent: nextTick');
      //  // canvasとそのwrapperのdivのelementを取得する。
      //  //this.canvasWrapperWidth = this.canvasWrapper.width();
      //  
      //  this.runWordCloud2();
      //});
    },
    setMinFrequency: function(position) {
      // 既存の頻度の最小値よりも小さい場合は更新する。
      if (this.minFrequency === null || position.length < this.minFrequency) {
        this.minFrequency = position.length;
      }
    },
    setMaxFrequency: function(position) {
      // 既存の頻度の最大値よりも大きい場合は更新する。
      if (this.maxFrequency === null || position.length > this.maxFrequency) {
        this.maxFrequency = position.length;
      }
    },
    filterData: function() {
      var me = this;
      var filtered = [];

      // 元データを見て、範囲内の出現のみ抽出する。
      // 元データの登場タイミングは0~1で示されているため、
      // filterで抽出する。
      for (let key in this.data) {
        var position = this.data[key].filter(function(v) {
          return v >= me.lower && v <= me.upper;
        });

        filtered.push([key, position]);

        // 最大値と最小値の更新。
        this.setMinFrequency(position);
        this.setMaxFrequency(position);
      }

      this.dataFiltered = filtered;
    },
    setSliderParam: function(sliderParam) {
      // スライダーを操作した時に呼び出される。
      // 描画する範囲の設定と再描画を実施。
      this.lower = sliderParam.lower;
      this.upper = sliderParam.upper;

      this.runWordCloud2();
    },
    runWordCloud2: function() {
      // スライダーの操作時に呼び出される。
      // タグクラウドを再描画する。
      //console.log('runWordCloud2');
      //console.log(this.canvas);
      //console.log(this.canvasWrapperWidth);

      var me = this;
      this.filterData();
      //console.log(this.dataFiltered);
      //console.log(this.dataSorted);

      WordCloud(
        //document.getElementById(this.canvasId),
        //this.canvas.get(0),
        this.canvas,
        {
          list: this.dataSorted,
          rotateRatio: 0.5,
          rotationSteps: 2,
          fontFamily: 'Hiragino Mincho Pro, serif',
          gridSize: Math.round(16 * this.canvasWrapperWidth / 1024),
          weightFactor: function(position) {
            //console.log('weightFactor');
            
            var frequency = position.length;
            //console.log(frequency);

            // 頻度が0、つまり範囲内では登場しなかった場合は0で処理を終える。
            if (frequency === 0) return 0;

            // 頻度を標準化し、0~1の範囲の値に変換する。
            var frequency_normalized = (
              (frequency - me.minFrequency) / (me.maxFrequency - me.minFrequency));

            // 標準化した値から、フォントのweightを計算する。
            var weight = Math.pow(
              (frequency_normalized + 1), 4.0) * (me.canvasWrapperWidth / 32);

            return weight;
          },
          //backgroundColor: '#f8f9fa',
          backgroundColor: '#FFFFFF',
        }
      );
      //console.log(WordCloud);
    },
  }
}
</script>


<style scoped>
  .noUi-connect {
    background: #007bff;
  }
</style>
