<template>
  <ContentWrapper
    v-bind:classNames="classNames"
    v-bind:idName="idName"
    v-bind:headerTitle="headerTitle"
    v-bind:apiUrl="apiUrl"
    v-bind:isRendering="isRendering"
    v-on:renderContent="renderContent"
  >
    <template v-slot:content>
      <div id="omusubi-wrapper"></div>
      <ToggleSlider v-bind:sliderId="sliderId" v-on:Omusubi2SliderParam="setSliderParam"/>
    </template>
  </ContentWrapper>

  <!--
  <div id="Omusubi2" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <b-card header="ネットワーク" header-tag="header" header-bg-variant="white">
      <div id="omusubi-wrapper"></div>
      <ToggleSlider v-bind:sliderId="sliderId" v-on:OmusubiSliderParam="setSliderParam"/>
    </b-card>
  </div>
  -->
</template>


<script>
import $ from 'jquery'
import * as d3 from 'd3'

import ContentWrapper from './ContentWrapper.vue';
import ToggleSlider from './ToggleSlider.vue'

export default {
  //name: 'Omusubi2',
  name: 'comtent-omusubi2',
  components: {
    ContentWrapper,
    ToggleSlider,
  },
  data: function() {
    return {
      classNames: 'col-sm-12 col-md-12 col-lg-12 col-xl-12',
      idName: 'Omusubi2',
      headerTitle: 'ネットワーク',
      // APIのエンドポイント
      //apiUrl: 'https://jsonplaceholder.typicode.com/users?ID=12345',
      isRendering: true, // 描画中かどうかのフラグ。

      sliderId: 'Omusubi2',
      svgWrapperId: 'omusubi-wrapper',

      svg: null, //svgオブジェクト。
      svgWrapper: null, //svgのwrapperオブジェクト。
      svgWrapperWidth: null, //wrapperの幅。
      container: null,

      svgGDefs: null, // d3.jsの細かいパラメータ。特に矢印の設定。
      svgGLinks: null, // svg内に生成するリンク用オブジェクト。
      svgGNodes: null, // svg内に生成するノード用オブジェクト。
      svgGLabels: null, // svg内に生成するラベル用オブジェクト。
      svgGraphLayout: null, // link, node, labelのレイアウトに関するオブジェクト。

      lower: 0.0, // スライダーで指定した範囲の下限。
      //upper: 100.0, // スライダーで指定した範囲の上限。
      upper: 1.0, // スライダーで指定した範囲の上限。

      // Ajaxで受け取った生データ。デフォルトでは空っぽ。
      nodeData: {
        nodes: [],
        links: {}
      },
      // 実際にグラフを描画するためのデータ。
      graph: {
        nodes: [],
        links: []
      },
    };
  },
  computed: {
    apiUrl: function() {
      // 動画の基本情報を取得するAPIのエンドポイントを作成する。
      return [
        //'https://toretora.net/api/video',
        //'https://8yn3yliewk.execute-api.ap-northeast-1.amazonaws.com/prod/api/video',
        //'https://toretora.net/api/video',
        //this.$apiEndpoint + 'video',
        this.$apiDomain + 'api/video',
        this.$route.params.channelId,
        this.$route.params.videoId,
        'omusubi2'
        ].join('/');
    },
    svgWrapperHeight: function() {
      // wrapperの高さを返す。
      return this.svgWrapperWidth * 0.7;
    },
    svgWrapperIdForjQuery: function() {
      return '#' + this.svgWrapperId;
    },
  },
  mounted: function() {
    /*
    var me = this;

    // 描画先のエレメントの取得と、幅の取得。
    this.svgWrapper = $(this.svgWrapperIdForjQuery);
    this.svgWrapperWidth = this.svgWrapper.width();

    // svgオブジェクトとgオブジェクトの作成。
    this.svg = d3.select(this.svgWrapperIdForjQuery).append('svg')
      .attr('width', this.svgWrapperWidth)
      .attr('height', this.svgWrapperHeight);

    this.container = this.svg.append('g');

    // ズーム機能の定義。
    this.svg.call(d3.zoom()
      .scaleExtent([.1, 4])
      .on('zoom', function() {
        me.container.attr('transform', d3.event.transform);
      })
    );

    // 初回のデータ作成と描画。
    this.runOmusubi();
    */
  },
  methods: {
    renderContent: function(jsonData) {
      // 子コンポーネントでAJAX通信でデータを取得した際に呼び出されるメソッド。
      // コンテンツの描画の処理を行った後、
      // 描画完了のフラグを立て、子コンポーネントに渡す。

      //console.log(JSON.stringify(jsonData, null, 2));
      //this.nodeData = jsonData;
      this.nodeData = JSON.parse(jsonData.Omusubi2);
      //console.log(this.nodeData);

      // 以下テストコード。
      //this.nodeData = {
      //  nodes: [
      //    '一言',
      //    '言える'
      //  ],
      //  links: {
      //    '0_1': [0.00146],
      //  }
      //};

      this.isRendering = false;


      // svgオブジェクトの作成や初期化処理は
      // DOMのレンダリングが完了した後に実施する。
      var me = this;
      this.$nextTick(function() {
        // 描画先のエレメントの取得と、幅の取得。
        me.svgWrapper = $(me.svgWrapperIdForjQuery);
        me.svgWrapperWidth = me.svgWrapper.width();

        // svgオブジェクトとgオブジェクトの作成。
        me.svg = d3.select(me.svgWrapperIdForjQuery).append('svg')
          .attr('width', me.svgWrapperWidth)
          .attr('height', me.svgWrapperHeight);

        me.container = me.svg.append('g');

        // ズーム機能の定義。
        me.svg.call(d3.zoom()
          .scaleExtent([.1, 4])
          .on('zoom', function() {
            me.container.attr('transform', d3.event.transform);
          })
        );

        // 初回のデータ作成と描画。
        me.runOmusubi2();
      });
    },
    setSliderParam: function(sliderParam) {
      // スライダーを操作した時に呼び出される。
      // 描画する範囲の設定と再描画を実施。
      this.lower = sliderParam.lower;
      this.upper = sliderParam.upper;

      // データの初期化。
      this.initData();

      // スライダーで指定したレンジでのデータ作成と再描画。
      this.runOmusubi2();
    },
    initData: function() {
      // 描画用データや描画されているオブジェクトを初期化する。

      // svg内のオブジェクトの初期化処理。
      // スライダーを操作時の再描画のタイミングやらで、
      // 描画前に古いデータを削除する。
      $(this.svgWrapperIdForjQuery + ' > svg > g').empty();

      // 描画用データの初期化。
      this.graph.nodes = [];
      this.graph.links = [];
    },
    generateNode: function(_nodes, nodeId, value=1) {
      return {
        id: parseInt(nodeId),
        value: value,
        name: _nodes[nodeId]
      };
    },
    generateLink: function(sourceNodeId, targetNodeId) {
      return {
        source: parseInt(sourceNodeId),
        target: parseInt(targetNodeId)
        //name: null
      };
    },
    setSvgGDefs: function() {
      // this.d3Defs.data(['arrowHead']);
      this.svgGDefs = this.container
        .append('defs')
        .selectAll('marker')
        .data(['arrowHead'])
        .enter()
          .append('marker')
          .attr('id', function(d) { return d; })
          .attr('viewBox', '0 -5 10 10')
          .attr('refX', 30)
          .attr('refY', 0)
          //ここの値で矢印のサイズを調整する。
          .attr('markerWidth', 5)
          .attr('markerHeight', 5)
          .attr('orient', 'auto')
          .append('path')
          .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
          .attr('fill', '#999')
          .style('opacity', '0.8');
    },
    setSvgGLinks: function() {
      this.svgGLinks = this.container
        .append('g')
        .attr('class', 'links')
        .selectAll('line')
        .data(this.graph.links)
        .enter()
          .append('line')
          .attr('stroke-width', 1)
          .attr('stroke', '#ccc')
          .style('marker-end','url(#arrowHead)');
    },
    setSvgGNodes: function() {
      var me = this;
      
      this.svgGNodes= this.container
        .append('g')
        .attr('class', 'nodes')
        .selectAll('circle')
        .data(this.graph.nodes)
        .enter()
          .append('circle')
          .attr('r', 10)
          //.attr("r", function(d) {
          //  return d.value * 7 / 2;
          //})
          .attr('fill', function() {
            return 'hsl(' +
              (Math.random() * 360).toFixed() + ',' +
              (Math.random() * 30 + 70).toFixed() + '%,' + '80%)';
          })
          .attr('id', function(d) {
            return 'circle-' + d.id;
          })
          .call(d3.drag()
            .on('start', this.dragStarted)
            .on('drag', this.dragged)
            .on('end', this.dragEnded));

      // ノードフォーカス処理。
      this.svgGNodes.on('mouseover', function() {
        // mouseoverされたノードのidを取得する。
        var id = d3.select(d3.event.target).datum().id;
        //console.log(id);
        //console.log(me.svgGLinks);
        //console.log(me.svgGNodes);
        //console.log(me.svgGLabels);

        // mouseoverされたノードをsourceかtargetで持つlinkに対して処理を行う。
        me.svgGLinks.style('opacity', function(v) {
          if (v.target.id == id || v.source.id == id) {
            // linkに関係するnode, labelは表示するためのクラスを付与する。
            d3.select('#circle-' + v.target.id).attr('class', 'focused');
            d3.select('#circle-' + v.source.id).attr('class', 'focused');
            d3.select('#text-' + v.target.id).attr('class', 'focused');
            d3.select('#text-' + v.source.id).attr('class', 'focused');
            return 1;
          // 条件を満たさないlinkは非表示とする。
          } else {
            return 0.1;
          }
        });

        //1度全node, labelを非表示にした後、
        // フォーカスするクラスを持つものだけ再表示させる。
        me.svgGNodes.style('opacity', 0.1);
        me.svgGLabels.style('opacity', 0.1);
        d3.selectAll('.focused').style('opacity', 1);
      });

      // フォーカス解除処理。
      this.svgGNodes.on('mouseout', function() {
        //全オブジェクトからフォーカス用クラスの除去を行い、再表示させる。
        me.svgGNodes
          .attr('class', null)
          .style('opacity', 1);
        me.svgGLabels
          .attr('class', null)
          .style('opacity', 1);
        me.svgGLinks
          .style('opacity', 1);
      });
    },
    setSvgGLabels: function() {
      this.svgGLabels = this.container
        .append('g')
        .attr('class', 'labels')
        .selectAll('text')
        .data(this.graph.nodes)
        .enter()
          .append('text')
          .text(function(d) {
            return d.name;
          })
          .attr('id', function(d) {
            return 'text-' + d.id;
          })
          .attr('text-anchor', 'middle')
          .style('pointer-events', 'none')
          .style('font-size', 12)
          .style('font-family', 'Arial')
          .style('fill', '#000');
    },
    setSvgGraphLayout: function() {
      this.svgGraphLayout = d3.forceSimulation(this.graph.nodes)
        .force('charge', d3.forceManyBody().strength(-200))
        .force('center', d3.forceCenter(
          this.svgWrapperWidth/2, this.svgWrapperHeight/2))
        .force('link', d3.forceLink(this.graph.links)
          .id(function(d) {
            return d.id;
          })
          .distance(50)
          .strength(1)
        )
        .on('tick', this.ticked);
    },
    ticked: function() {
      this.svgGLinks
        .attr('x1', function(d) { return d.source.x; })
        .attr('y1', function(d) { return d.source.y; })
        .attr('x2', function(d) { return d.target.x; })
        .attr('y2', function(d) { return d.target.y; });
      this.svgGNodes
        .attr('cx', function(d) { return d.x; })
        .attr('cy', function(d) { return d.y; });
      this.svgGLabels
        .attr('x', function(d) { return d.x; })
        .attr('y', function(d) { return d.y; });
    },
    dragStarted: function(d) {
      if(!d3.event.active) this.svgGraphLayout.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    },
    dragged: function(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    },
    dragEnded: function(d) {
      if(!d3.event.active) this.svgGraphLayout.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    },
    runOmusubi2: function() {
      var me = this;

      //スライダーの範囲内のノードを計算するための変数。
      var _nodes = {};

      // 範囲内のnode, linkのオブジェクトを作成する。
      for (let linkId in this.nodeData.links) {
        //範囲内で登場する回数を取得する。
        var positions = this.nodeData.links[linkId].filter(function(value) {
          return ((value > me.lower) && (value < me.upper));
        });

        //範囲内に1つもpositionがない、つまり登場しない場合は処理を中断する。
        if (positions.length <= 0) {
          continue
        }

        //linkIdから登場するnodeを設定する。
        var ids = linkId.split('_');
        for (let nodeId of ids) {
          if (!(nodeId in _nodes)) {
            _nodes[nodeId] = this.generateNode(this.nodeData.nodes, nodeId);
          }
        }
        this.graph.links.push(this.generateLink(ids[0], ids[1]));
      }
      this.graph.nodes = Object.values(_nodes);

      this.setSvgGDefs();
      this.setSvgGLinks();
      this.setSvgGNodes();
      this.setSvgGLabels();
      this.setSvgGraphLayout();
    },
  },
}
</script>


<style scoped>
</style>
